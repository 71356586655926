* {
  margin: 0px;
  padding: 0;
}

html,
body {
  overflow: hidden;
  color: white;
}

.button,
.cancel_btn {
  position: absolute;
  z-index: 0;
  bottom: -100px;
  left: 45%;
  width: 350px;
  display: none;
  justify-content: space-around;
  height: 70px;
  padding: 0;
  background-image: linear-gradient(
    to bottom,
    #4fcb90,
    #71d3a4,
    #8fdab8,
    #ace1ca,
    #c9e8db
  );
  border: none;
  outline: none;
  border-radius: 5px;
  overflow: hidden;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3px;
  cursor: pointer;
}

.cancel_btn {
  left: 50%;
  width: 130px;
  height: 80px;
  border-radius: 99px;
  justify-content: space-evenly;
}

.button2 {
  display: none;
  border: none;
}

.button:hover,
.cancel_btn:hover {
  background-image: linear-gradient(
    to bottom,
    #c9e8db,
    #ace1ca,
    #8fdab8,
    #71d3a4,
    #4fcb90
  );
}

/* .button2:hover {
  background-image: linear-gradient(
    to bottom,
    #c9e8db,
    #ace1ca,
    #8fdab8,
    #71d3a4,
    #4fcb90
  );
} */

.button-text,
.button-icon,
.escBtn-text {
  display: inline-flex;
  align-items: center;
  padding: 0 30px;
  height: 100%;
}

.escBtn-text {
  padding: 0;
}

.button-icon {
  font-size: 1.5em;
}

.button-text1,
.button-icon1 {
  display: inline-flex;
  align-items: center;
  padding: 0 40px;
  height: 100%;
}

.button-icon1 {
  font-size: 1.5em;
}

.button-text2,
.button-icon2 {
  display: inline-flex;
  align-items: center;
  padding: 0 60px;
  height: 100%;
}

.button-icon2 {
  font-size: 1.5em;
}

.button-text3,
.button-icon3 {
  display: inline-flex;
  align-items: center;
  padding: 0 42px;
  height: 100%;
}

.button-icon3 {
  font-size: 1.5em;
}

.button.visible,
.cancel_btn.visible {
  transform: translateY(-120px);
  transition: transform 0.2s;
}

.button.invisible,
.cancel_btn.invisible {
  transform: translateY(120px);
  transition: transform 0.3s;
}

.button2.visible {
  /* transform: translateY(-120px); */
  transform: translate(-50%, -120px);
  transition: transform 0.2s;
}
.button2.invisible {
  /* transform: translateY(120px); */
  transform: translate(-50%, 120px);
  transition: transform 0.3s;
}

.icon {
  position: absolute;
  bottom: -350px;
  margin-left: 50%;
  font-size: 80px;
}

.icon__facebook {
  color: #4267b2;
}

.icon__whatsapp {
  color: #25d366;
}

.icon__outlook {
  color: #127cd6;
  font-size: 64px;
}

.icon__linkedin {
  color: #0077b5;
  font-size: 60px;
}

.icon__facebook,
.icon__whatsapp,
.icon__linkedin,
.icon__outlook {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  box-shadow: 0.3rem 0.3rem 0.6rem #c8d0e7, -0.2rem -0.2rem 0.5rem #ffffff;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: white;
  position: absolute;
  left: 50%;
}

.icon__facebook.visible {
  transform: translate(-50%, -470px);
  transition: transform 0.3s;
}

.icon__facebook.invisible {
  transform: translateY(120px);
  transition: transform 0.3s;
}

.icon__whatsapp.visible {
  transform: translate(-50%, -470px);
  transition: transform 0.3s;
}

.icon__whatsapp.invisible {
  transform: translateY(120px);
  transition: transform 0.3s;
}

.icon__outlook.visible {
  transform: translate(-50%, -470px);
  transition: transform 0.3s;
}

.icon__outlook.invisible {
  transform: translateY(120px);
  transition: transform 0.3s;
}

.icon__linkedin.visible {
  transform: translate(-50%, -470px);
  transition: transform 0.3s;
}

.icon__linkedin.invisible {
  transform: translateY(120px);
  transition: transform 0.3s;
}

.icon__facebook:active,
.icon__whatsapp:active {
  box-shadow: inset 0.2rem 0.2rem 0.5rem #c8d0e7,
    inset -0.2rem -0.2rem 0.5rem #ffffff;
  width: 5.5rem;
  height: 5.5rem;
  font-size: 90px;
}

.icon__facebook:hover,
.icon__whatsapp:hover {
  width: 5.5rem;
  height: 5.5rem;
  font-size: 90px;
}

.icon__outlook:active {
  box-shadow: inset 0.2rem 0.2rem 0.5rem #c8d0e7,
    inset -0.2rem -0.2rem 0.5rem #ffffff;
  width: 5.5rem;
  height: 5.5rem;
  font-size: 72px;
}

.icon__outlook:hover {
  width: 5.5rem;
  height: 5.5rem;
  font-size: 72px;
}

.icon__linkedin:active {
  box-shadow: inset 0.2rem 0.2rem 0.5rem #c8d0e7,
    inset -0.2rem -0.2rem 0.5rem #ffffff;
  width: 5.5rem;
  height: 5.5rem;
  font-size: 68px;
}

.icon__linkedin:hover {
  width: 5.5rem;
  height: 5.5rem;
  font-size: 68px;
}

.progress-bar-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#progress-bar {
  width: 30%;
  margin-top: 0.5%;
  height: 2%;
}

#initial-arrow-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
}

.display-none {
  display: none !important;
}

.keysContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.wasdKeys,
.arrowKeys {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.asdKeys,
.bottomKeys {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wKey p,
.asdKeys p,
.upKey,
.bottomKeys p,
.shiftKey p {
  background: #fff;
  color: #000;
  border-radius: 3px;
  font-size: 20px;
  padding: 5px 6.5px;
  margin: 2px;
}
.wKey p,
.asdKeys p,
.shiftKey p {
  padding: 5px 9px;
}

.movement-description,
.toggle-run-description {
  background: #fff;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 12px;
  text-align: center;
}

.movement-description {
  margin: 10px 19px;
}

.toggle-run-description {
  margin: 10px 50px;
}

.movement-description span,
.toggle-run-description span {
  color: crimson;
}

.fade-in {
  animation: fadeIn 3s;
  display: block !important;
}

.fade-out {
  animation: fadeOut 3s;
}

.button-add {
  display: flex;
}

.loading-bar {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background: #ffffff;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.5s;
  will-change: transform;
}

.loading-bar.ended {
  transform-origin: top right;
  transition: transform 1.5s ease-in-out;
}

.point {
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-animation: moveUD 1s infinite alternate;
  animation: moveUD 1s infinite alternate;
}

.point:hover .text {
  opacity: 1;
}

.point .label {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ff0000;
  background-color: #00000077;
  border: 1px solid #ffffff77;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 40px;
  font-weight: 100;
  font-size: 14px;
  cursor: help;
  transform: scale(0, 0);
  transition: transform 0.3s;
}

.point.visible .label {
  transform: scale(0.75, 0.75);
}

.point .text {
  position: absolute;
  top: 30px;
  left: -120px;
  width: 200px;
  padding: 20px;
  border-radius: 4px;
  background: #ff0000;
  background-color: #00000077;
  border: 1px solid #ffffff77;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 1.3em;
  font-weight: 100;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.websiteLink {
  position: absolute;
  right: 2%;
  bottom: 2%;
  color: #fff;
  font-family: cursive;
  font-size: 18px;
  display: none;
}

.websiteLink a {
  color: #ffffff;
}

/* img.vert-move {
  -webkit-animation: moveUD 1s infinite alternate;
  animation: moveUD 1s infinite alternate;
}
img.vert-move {
  -webkit-animation: moveUD 1s infinite alternate;
  animation: moveUD 1s infinite alternate;
} */

@-webkit-keyframes moveUD {
  0% {
    /* transform: translateY(0); */
    top: 50%;
  }
  100% {
    /* transform: translateY(-10px); */
    top: 52%;
  }
}
@keyframes moveUD {
  0% {
    /* transform: translateY(0); */
    top: 50%;
  }
  100% {
    /* transform: translateY(-10px); */
    top: 52%;
  }
}

#divPhysicsDebuggerMenu {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
